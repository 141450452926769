import { Icon } from '@/components/icon/BpIcon';
import { localizeDate } from '@/utils/date';
import clone from '@sahnee/clone';
import { defineStore } from 'pinia';
import { $gettext } from 'vue-gettext';
import { Customer, useCustomerStore } from './customer';

interface State {
  supportStatuses: SupportStatus[];
}

export type SupportStatusId = 'active' | 'canceled' | 'expired' | 'expired_grace_period' | 'not_purchased' | 'pilot_support' | 'undefined';

/**
 * A support status.
 */
export interface SupportStatus {
  _id: SupportStatusId;
  icon: Icon;
  name: string;
}

const defaultSupportStatus: SupportStatus = {
  _id: 'undefined',
  icon: 'question',
  name: '',
}

/**
 * The support status store.
 */
export const useSupportStatusStore = defineStore('supportStatus', {
  ///-------------------------------------------------------------------
  /// STATE
  ///-------------------------------------------------------------------
  state: (): State => {
    return {
      supportStatuses: [
        {
          _id: 'active',
          icon: {
            icon: ['far', 'calendar'],
            color: 'green',
          },
          name: $gettext('Active'),
        },
        {
          _id: 'canceled',
          icon: {
            icon: 'xmark-large',
            color: 'red',
          },
          name: $gettext('Canceled'),
        },
        {
          _id: 'expired',
          icon: {
            icon: ['far', 'clock'],
            color: 'red',
          },
          name: $gettext('Expired'),
        },
        {
          _id: 'expired_grace_period',
          icon: {
            icon: 'timer',
            color: 'yellow',
          },
          name: $gettext('Expired (Grace period)'),
        },
        {
          _id: 'not_purchased',
          icon: {
            icon: 'money-bill-wave',
            color: 'red',
          },
          name: $gettext('Not purchased'),
        },
        {
          _id: 'pilot_support',
          icon: {
            icon: 'user-pilot',
            color: 'green',
          },
          name: $gettext('Pilot support'),
        },
      ]
    }
  },
  ///-------------------------------------------------------------------
  /// GETTERS
  ///-------------------------------------------------------------------
  getters: {
    /**
     * Searches for the support status with the given ID and returns its name.
     * @param id The ID of the support status.
     * @returns The name of the support status.
     */
    getById: (state: State) => {
      return (id: SupportStatusId) => {
        return state.supportStatuses.find(supportStatus => supportStatus._id === id);
      }
    },
    /**
     * Searches for the support status with the given ID and returns its name.
     * @param id The ID of the support status.
     * @returns The name of the support status.
     */
    getByCustomer() {
      return ((customer: Customer, productIds: Customer['productselection'] = 'bestinformed') => {
        if (!Array.isArray(productIds)) {
          productIds = [productIds];
        }
        const now = new Date();

        const supportStatuses = new Map<string, SupportStatus>();
        for (const productId of productIds) {
          switch (productId) {
            case 'bestinformed':
              if (customer.pilot) {
                const status = this.getById('pilot_support');
                if (!status) { break }
                supportStatuses.set(productId, status);
                break;
              } else if (customer.supportCancel && customer.canceledTo && new Date(customer.canceledTo).getTime() <= new Date().getTime()) {
                const status = this.getById('canceled');
                if (!status) { break }
                supportStatuses.set(productId, status);
                break;
              } else if (!customer.supportTo) {
                const status = this.getById('not_purchased');
                if (!status) { break }
                supportStatuses.set(productId, status);
                break;
              } else if (new Date(customer.supportTo).getTime() < now.getTime()) {
                const supportTo = new Date(clone(customer.supportTo));
                const gracePeriodEnd = new Date(supportTo.setMonth(supportTo.getMonth() + 2));
                const status = gracePeriodEnd.getTime() < now.getTime() ? this.getById('expired') : this.getById('expired_grace_period');
                if (!status) { break }
                supportStatuses.set(productId, status);
                break;
              }
              supportStatuses.set(productId, { ...clone(this.getById('active') || defaultSupportStatus), name: localizeDate(customer.supportTo) })
              break;
            case 'bestproxy':
              if (customer.supportCancel) {
                const status = this.getById('canceled');
                if (!status) { break }
                supportStatuses.set(productId, status);
                break;
              } else if (!customer.bestproxyruntimeto) {
                const status = this.getById('not_purchased');
                if (!status) { break }
                supportStatuses.set(productId, status);
                break;
              } else if (new Date(customer.bestproxyruntimeto).getTime() < now.getTime()) {
                const supportTo = new Date(clone(customer.bestproxyruntimeto));
                const gracePeriodEnd = new Date(supportTo.setMonth(supportTo.getMonth() + 2));
                const status = gracePeriodEnd.getTime() < now.getTime() ? this.getById('expired') : this.getById('expired_grace_period');
                if (!status) { break }
                supportStatuses.set(productId, status);
                break;
              }
              supportStatuses.set(productId, { ...clone(this.getById('active') || defaultSupportStatus), name: localizeDate(customer.bestproxyruntimeto) })
              break;
            case 'bestzero':
              if (customer.supportCancel) {
                const status = this.getById('canceled');
                if (!status) { break }
                supportStatuses.set(productId, status);
                break;
              } else if (!customer.supportTo) {
                const status = this.getById('not_purchased');
                if (!status) { break }
                supportStatuses.set(productId, status);
                break;
              } else if (new Date(customer.supportTo).getTime() < now.getTime()) {
                const supportTo = new Date(clone(customer.supportTo));
                const gracePeriodEnd = new Date(supportTo.setMonth(supportTo.getMonth() + 2));
                const status = gracePeriodEnd.getTime() < now.getTime() ? this.getById('expired') : this.getById('expired_grace_period');
                if (!status) { break }
                supportStatuses.set(productId, status);
                break;
              }
              supportStatuses.set(productId, { ...clone(this.getById('active') || defaultSupportStatus), name: localizeDate(customer.supportTo) })
              break;
            default:
              break;
          }
        }
        return productIds.length === 1 ? supportStatuses.get(productIds[0]) : supportStatuses;
      }) as ((customer: Customer, productIds?: string | [string]) => SupportStatus) & ((customer: Customer, productIds?: string[]) => Map<string, SupportStatus>)
    },
    /**
     * Searches for the support status with the given ID and returns its name.
     * @param id The ID of the support status.
     * @returns The name of the support status.
     */
    getByCustomerId() {
      return (id: string) => {
        const customerStore = useCustomerStore(true);
        const customer = customerStore.getById(id);
        return this.getByCustomer(customer);
      }
    },
  },
})