
import { $gettext } from 'vue-gettext';
import { computed, defineComponent, ref } from 'vue';
import { Contact, useContactStore } from '@/stores/contact';
import { copyToClipboard } from '@/utils/navigator';
import { MailListFilter, CombinationKey, Entries, Products, ProductBestinformed, ProductBestinformedData, ProductHosting } from './MailListGenerator';
import { PopoverElement } from '@/components/popover/BpPopoverMenu';
import { useAppStore } from '@/stores/app';
import { useCountryStore } from '@/stores/country';
import { useCustomerStore } from '@/stores/customer';
import { useEditionStore } from '@/stores/edition';
import { useHostingStatusStore } from '@/stores/hostingStatus';
import { useLanguageStore } from '@/stores/language';
import { User, useUserStore } from '@/stores/user';
import { useSupportStatusStore } from '@/stores/supportStatus';
import { useVersionStore } from '@/stores/version';
import clone from '@sahnee/clone';
import { Header } from '@/components/table/BpTable';
import { useSalutationStore } from '@/stores/salutation';

export default defineComponent({
  name: 'bp-mail-list-generator-view',
  setup() {
    ///-------------------------------------------------------------------
    /// STORES
    ///-------------------------------------------------------------------

    const customerStore = useCustomerStore(true);
    const contactStore = useContactStore();
    const userStore = useUserStore(true);

    const salutationStore = useSalutationStore();
    const languageStore = useLanguageStore();
    const countryStore = useCountryStore();
    const editionStore = useEditionStore(true);
    const appStore = useAppStore();
    const versionStore = useVersionStore();

    const supportStatusStore = useSupportStatusStore();
    const supportStatuses = computed(() => supportStatusStore.supportStatuses as PopoverElement[]);

    const hostingStatusStore = useHostingStatusStore();
    const hostingStatuses = computed(() => hostingStatusStore.hostingStatuses as PopoverElement[]);

    ///-------------------------------------------------------------------
    /// MAIL LIST
    ///-------------------------------------------------------------------

    const personList = ref<(Contact | User)[]>([]);
    const mailList = ref<Set<string>>(new Set<string>());

    const preview = ref(false)

    const productsCombination = computed(() => Object.keys(filter.value.products)[0] as CombinationKey);
    const includedAppsCombination = computed(() => Object.keys(filter.value.products[productsCombination.value]?.bestinformed?.bestinformed6?.apps.included || [])[0] as CombinationKey);
    const excludedAppsCombination = computed(() => Object.keys(filter.value.products[productsCombination.value]?.bestinformed?.bestinformed6?.apps.excluded || [])[0] as CombinationKey);

    function updateProductsCombination(combination: CombinationKey) {
      if (combination === productsCombination.value) {
        return;
      }
      const products = filter.value.products[productsCombination.value];
      delete filter.value.products[productsCombination.value];
      filter.value.products[combination] = products;
    }

    function updateAppsCombination(combination: CombinationKey, includedOrExcluded: keyof Exclude<ProductBestinformed['bestinformed6'], undefined>['apps']) {
      const currentCombination = includedOrExcluded === 'included' ? includedAppsCombination.value : excludedAppsCombination.value;
      if (combination === currentCombination) {
        return;
      }
      const products = filter.value.products[productsCombination.value];
      if (!products) {
        return;
      }
      const bestinformed = products.bestinformed;
      if (!bestinformed) {
        return;
      }
      const bestinformed6 = bestinformed.bestinformed6;
      if (!bestinformed6) {
        return;
      }
      const apps = bestinformed6.apps[includedOrExcluded][currentCombination];
      delete bestinformed6.apps[includedOrExcluded][currentCombination];
      bestinformed6.apps[includedOrExcluded][combination] = apps;
    }

    const filter = ref<MailListFilter>({
      by: {
        language: [],
      },
      support: [],
      products: {
        one: {
          bestinformed: {
            bestinformed6: {
              edition: [],
              apps: {
                included: {
                  one: [],
                },
                excluded: {
                  one: [],
                },
              },
              licenseType: 'both',
            },
          }
        }
      },
      docTypes: ['contact', 'new_contact'],
    });

    function toggleProduct(product: keyof Products) {
      const combination = productsCombination.value;
      if (!combination) {
        return;
      }
      const products = filter.value.products[combination];
      if (!products) {
        return;
      }
      if (Object.keys(products).includes(product)) {
        delete filter.value.products[combination]?.[product];
      } else {
        switch (product) {
          case 'bestinformed': {
            products.bestinformed = {
              bestinformed6: {
                edition: [],
                apps: {
                  included: {
                    one: [],
                  },
                  excluded: {
                    one: [],
                  },
                },
                licenseType: 'both',
              },
            };
            break;
          }
          case 'bestproxy': {
            products.bestproxy = {};
            break;
          }
          case 'hosting': {
            products.hosting = {
              status: []
            };
            break;
          }
          case 'bestzero': {
            products.bestzero = {};
            break;
          }
        }
      }
    }

    function toggleBestinformedVersion(version: keyof ProductBestinformed) {
      const combination = productsCombination.value;
      if (!combination) {
        return;
      }
      const bestinformed = filter.value.products[combination]?.bestinformed;
      if (!bestinformed) {
        return;
      }
      if (Object.keys(bestinformed).includes(version)) {
        delete bestinformed[version];
      } else {
        switch (version) {
          case 'bestinformed5': {
            bestinformed.bestinformed5 = {
              edition: []
            };
            break;
          }
          case 'bestinformed6': {
            bestinformed.bestinformed6 = {
              edition: [],
              apps: {
                included: {
                  one: [],
                },
                excluded: {
                  one: [],
                },
              },
              licenseType: 'both',
            };
            break;
          }
        }
      }
    }

    function generateMailList() {
      const products = filter.value.products[productsCombination.value] as Products;

      // We filter all customers by the selected conditions
      const customerIds = customerStore.customers.filter(customer => {
        ///-------------------------------------------------------------------
        /// COUNTRY
        ///-------------------------------------------------------------------

        // If we filtered the results by country, we discard this customer if its country is not in the selection.
        if (Object.keys(filter.value.by)[0] === 'country' && filter.value.by.country && filter.value.by.country.length > 0 && !filter.value.by.country.includes(customer.country)) {
          return false;
        }

        ///-------------------------------------------------------------------
        /// SUPPORT
        ///-------------------------------------------------------------------

        // If we filtered the results the customer's support status, we discard this customer if its support status is not in the selection.
        if (filter.value.support.length > 0) {
          const supportStatus = supportStatusStore.getByCustomer(customer);
          if (!supportStatus || !filter.value.support.includes(supportStatus._id)) {
            return false;
          }
        }

        ///-------------------------------------------------------------------
        /// PRODUCTS
        ///-------------------------------------------------------------------

        // We iterate every selected product to check in detail whether we discard this customer or not.
        const productResult = [];
        for (const [product, productData] of Object.entries(products) as Entries<Products>) {
          switch (product) {

            // BESTINFORMED
            case 'bestinformed': {

              if (!customer.editionProduct || customer.editionProduct === 'bestzero') {
                productResult.push(false);
                break;
              }

              // We iterate every selectable option within the `bestinformed` product to check in detail whether we discard this customer or not.
              const bestinformedResult = [];
              for (const [version, versionData] of Object.entries(productData) as Entries<ProductBestinformed>) {
                switch (version) {

                  // VERSION 5
                  case 'bestinformed5': {
                    const version5Data = versionData as ProductBestinformedData<typeof version>;
                    // VERSION 5 > EDITION
                    // TODO: Improve logic!
                    if (
                      customer.editionProduct === 'Apps' || customer.products.bestinformed.version !== '5' ||
                      (
                        version5Data.edition &&
                        version5Data.edition.length > 0 &&
                        !version5Data.edition.includes(/basis/i.test(customer.editionProduct) ? 'Basis' : /enterprise/i.test(customer.editionProduct) ? 'Enterprise' : /profess/i.test(customer.editionProduct) ? 'Professional' : customer.editionProduct)
                      )
                    ) {
                      bestinformedResult.push(false);
                      break;
                    }

                    bestinformedResult.push(true);
                    break;
                  }

                  // VERSION 6
                  case 'bestinformed6': {
                    const version6Data = versionData as ProductBestinformedData<typeof version>;

                    // Migrated VERSION 5 to VERSION 6 customers
                    if (customer.products.bestinformed.version === '6' && customer.editionProduct !== 'Apps') {
                      const edition = editionStore.getById(customerStore.getVTigerBestinformedEdition(customer));
                      if (!edition) return;
                      const [id, { apps }] = Object.entries(edition.migrate['6'])[0];
                      const newEdition = editionStore.getById(id);
                      const migratedEdition = { ...newEdition, apps: [...new Set([...(newEdition?.apps || []), ...(apps || [])].filter(app => app !== id))] };
                      customer.apps = migratedEdition.apps;
                      console.log('migrated customer included ', customer.company, migratedEdition);
                      if (version6Data.edition && version6Data.edition.length > 0 && !version6Data.edition.includes(migratedEdition._id || '')) {
                        bestinformedResult.push(false);
                        break;
                      }
                    } else {
                      // VERSION 6 > EDITION
                      if (customer.editionProduct !== 'Apps' || (version6Data.edition && version6Data.edition.length > 0 && !version6Data.edition.includes(customer.editionApps))) {
                        bestinformedResult.push(false);
                        break;
                      }
                    }

                    // VERSION 6 > APPS
                    const includedApps = version6Data.apps.included[includedAppsCombination.value];
                    const excludedApps = version6Data.apps.excluded[excludedAppsCombination.value];

                    if (includedApps && includedApps.length > 0) {
                      if (!customer.apps || customer.apps.length === 0) {
                        bestinformedResult.push(false)
                        break;
                      }

                      // We iterate every selected app within the `bestinformed` product to check in detail whether we discard this customer or not.
                      const appsResult = [];
                      for (const includedAppId of includedApps) {
                        appsResult.push(customer.apps.includes(includedAppId));
                      }

                      // If we iterated any apps at all, we decide whether this product should be resulting in keeping the contacts of this customer or not.
                      if (appsResult.length > 0 && (
                        // If ALL apps should be in the customer's possession, ALL app results have to be `true`.
                        // Therefore if at least one `false` result was calculated, we discard this product.
                        (includedAppsCombination.value === 'all' && appsResult.includes(false)) ||
                        // If ONE app should be in the customer's possession, at lease ONE result has to be `true`.
                        // Therefore if all results are `false`, we discard this product.
                        (includedAppsCombination.value === 'one' && !appsResult.includes(true))
                      )) {
                        bestinformedResult.push(false)
                        break;
                      }
                    }

                    if (excludedApps && excludedApps.length > 0) {
                      if (!customer.apps || customer.apps.length === 0) {
                        bestinformedResult.push(false)
                        break;
                      }

                      // We iterate every selected app within the `bestinformed` product to check in detail whether we discard this customer or not.
                      const appsResult = [];
                      for (const excludedAppId of excludedApps) {
                        appsResult.push(!customer.apps.includes(excludedAppId));
                      }

                      // If we iterated any apps at all, we decide whether this product should be resulting in keeping the contacts of this customer or not.
                      if (appsResult.length > 0 && (
                        // If ALL apps should be in the customer's possession, ALL app results have to be `true`.
                        // Therefore if at least one `false` result was calculated, we discard this product.
                        (excludedAppsCombination.value === 'all' && appsResult.includes(false)) ||
                        // If ONE app should be in the customer's possession, at lease ONE result has to be `true`.
                        // Therefore if all results are `false`, we discard this product.
                        (excludedAppsCombination.value === 'one' && !appsResult.includes(true))
                      )) {
                        bestinformedResult.push(false)
                        break;
                      }
                    }

                    // VERSION 6 > SUBSCRIPTION
                    if (
                      (version6Data.licenseType === 'purchase' && customer.subscription) ||
                      (version6Data.licenseType === 'subscription' && !customer.subscription)
                    ) {
                      bestinformedResult.push(false);
                      break;
                    }

                    bestinformedResult.push(true);
                    break;
                  }
                }
              }

              // If ONE calculation was successful regarding keeping this product for further decision making whether we add this customer to the mail list or not, we add `true` to the product results.
              // Otherwise we add `false` to the results.
              productResult.push(!(bestinformedResult.length > 0 && !bestinformedResult.includes(true)));
              break;
            }

            // BESTPROXY
            case 'bestproxy': {
              if (!customer.bestproxy) {
                productResult.push(false);
                break;
              }

              productResult.push(true);
              break;
            }

            // HOSTING
            case 'hosting': {
              if (!customer.hosting) {
                productResult.push(false);
                break;
              }

              const hostingData = productData as ProductHosting;
              const hostingStatus = hostingStatusStore.getByCustomer(customer);
              if (hostingData.status.length > 0 && hostingStatus !== undefined && !hostingData.status.includes(hostingStatus._id)) {
                productResult.push(false);
                break;
              }

              productResult.push(true);
              break;
            }

            // BESTZERO
            case 'bestzero': {
              if (!customer.editionProduct || customer.editionProduct !== 'bestzero') {
                productResult.push(false);
                break;
              }

              productResult.push(true);
              break;
            }
          }
        }

        // If we iterated any products at all, we decide whether the contact of this customer will be added to the mail list or not.
        if (productResult.length > 0 && (
          // If ALL products have to fulfill our conditions, ALL product results have to be `true`.
          // Therefore if at least one `false` result was calculated, we discard this customer.
          (productsCombination.value === 'all' && productResult.includes(false)) ||
          // If ONE product has to fulfill our conditions, at least ONE product result has to be `true`.
          // Therefore if all results are `false`, we discard this customer.
          (productsCombination.value === 'one' && !productResult.includes(true))
        )) {
          return false;
        }

        return true;
      }).map(customer => customer._id);

      // Clear both the mail list which holds a set of email addresses and the person list which holds a list of corresponding contacts / registered users.
      personList.value = [];
      mailList.value = new Set<string>();

      // We iterate every contact & registered user, based on the initial selection and add every valid person the mail list.
      // If we encounter a duplicate, e.g. a contact and a registered user have the same email address, we discard the duplicated contact / registered user.
      for (const docType of filter.value.docTypes) {
        switch (docType) {
          case 'contact': {
            const contacts = contactStore.getByCustomerId(customerIds);
            for (const contact of contacts) {
              if (filter.value.by.language && filter.value.by.language.length > 0 && !filter.value.by.language.includes(contact.language)) {
                continue;
              }
              if (!mailList.value.has(contact.email)) {
                mailList.value.add(contact.email);
                personList.value.push(contact);
              }
            }
            break;
          }
          case 'new_contact': {
            const users = userStore.getByCustomerId(customerIds);
            for (const user of users) {
              if (filter.value.by.language && filter.value.by.language.length > 0 && !filter.value.by.language.includes(user.language)) {
                continue;
              }
              if (!mailList.value.has(user.email)) {
                mailList.value.add(user.email);
                personList.value.push(user);
              }
            }
            break;
          }
        }
      }

      // After the mail list is calculated, make the preview of the mail list visible.
      preview.value = true;
    }

    function copyToClipboardAndClosePreview() {
      copyToClipboard(Array.from(mailList.value).join('; '));
      preview.value = false;
    }

    const headers = computed<Header[]>(() => [
      {
        _id: 'companyName',
        name: $gettext('Company name'),
        width: '2fr',
      },
      {
        _id: 'email',
        name: $gettext('Mail'),
        width: '2fr',
      },
      {
        _id: 'salutation',
        name: $gettext('Salutation'),
        filter: salutationStore.salutations.map(
          salutation => ({
            ...salutation,
            detect: (_contact: Contact, contactSalutation: Contact['salutation']) => salutation._id === contactSalutation
          })
        ),
      },
      {
        _id: 'forename',
        name: $gettext('Firstname'),
      },
      {
        _id: 'surname',
        name: $gettext('Lastname'),
      },
      ...(Object.keys(filter.value.by)[0] === 'language'
        ? [{
            _id: 'language',
            name: $gettext('Language'),
            filter: [
              ...languageStore.languages.map(
                language => ({
                  ...language,
                  _id: '_' + language._id,
                  detect: (_contact: Contact, contactLanguage: Contact['language']) => language._id === contactLanguage
                })
              ),
              {
                _id: 'unknown',
                name: $gettext('Unknown'),
                detect: (_contact: Contact, contactLanguage: Contact['language']) => !contactLanguage || Array.isArray(contactLanguage)
              }
            ],
            transform: (_contact: Contact, contactLanguage: Contact['language']) => languageStore.getNameOf(contactLanguage, 'md'),
          }]
        : [{
            _id: 'country',
            name: $gettext('Country'),
            filter: countryStore.countries.map(
              country => ({
                ...country,
                detect: (contact: Contact) => country._id === customerStore.getById(contact.company).country
              })
            ),
            transform: (contact: Contact) => customerStore.getById(contact.company).country,
          }]
      ),
    ]);

    ///-------------------------------------------------------------------
    /// PRODUCTS
    ///-------------------------------------------------------------------

    const productTabs = [
      { _id: 'bestinformed', name: 'bestinformed' },
      { _id: 'bestproxy', name: 'bestproxy' },
      { _id: 'hosting', name: 'Hosting' },
      { _id: 'bestzero', name: 'bestzero' }
    ];

    const activeTab = ref('bestinformed');

    ///-------------------------------------------------------------------
    /// LICENSE TYPES
    ///-------------------------------------------------------------------

    const licenseTypes = [
      { _id: 'both', name: $gettext('Include all customers') },
      { _id: 'purchase', name: $gettext('Include customers that purchased Cordaware bestinformed<sup>®</sup>') },
      { _id: 'subscription', name: $gettext('Include customers that have a Cordaware bestinformed<sup>®</sup> subscription') },
    ];

    ///-------------------------------------------------------------------
    /// APPS
    ///-------------------------------------------------------------------

    function apps(key: keyof Exclude<ProductBestinformed['bestinformed6'], undefined>['apps']) {
      const products = filter.value.products[productsCombination.value];
      if (!products) {
        return [];
      }
      const bestinformed = products.bestinformed;
      if (!bestinformed) {
        return [];
      }
      const bestinformed6 = bestinformed.bestinformed6;
      if (!bestinformed6) {
        return [];
      }
      const apps = key === 'excluded' ? bestinformed6.apps.included[includedAppsCombination.value] : bestinformed6.apps.excluded[excludedAppsCombination.value];
      return clone([...appStore.apps.appPlus, ...appStore.apps.additionalApps]).map(app => {
        if (apps?.includes(app._id)) {
          (app as PopoverElement)._disabled = true;
          return app;
        }
        return app;
      });
    }

    ///-------------------------------------------------------------------
    /// RETURN
    ///-------------------------------------------------------------------

    return {
      activeTab,
      apps,
      appStore,
      copyToClipboardAndClosePreview,
      countryStore,
      editionStore,
      excludedAppsCombination,
      filter,
      generateMailList,
      headers,
      hostingStatuses,
      includedAppsCombination,
      languageStore,
      licenseTypes,
      mailList,
      personList,
      preview,
      productsCombination,
      productTabs,
      supportStatuses,
      toggleBestinformedVersion,
      toggleProduct,
      updateAppsCombination,
      updateProductsCombination,
      versionStore,
    }
  }
})
