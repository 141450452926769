import { defineStore } from 'pinia';
import { $gettext } from 'vue-gettext';

interface State {
  countries: Country[];
}

/**
 * A country.
 */
export interface Country {
  _id: string;
  name: string;
}

/**
 * The country store.
 */
export const useCountryStore = defineStore('country', {
  ///-------------------------------------------------------------------
  /// STATE
  ///-------------------------------------------------------------------
  state: (): State => {
    return {
      countries: [
        // DACH COUNTRIES
        {
          _id: 'Deutschland',
          name: $gettext('Germany'),
        },
        {
          _id: 'Österreich',
          name: $gettext('Austria'),
        },
        {
          _id: 'Schweiz',
          name: $gettext('Switzerland'),
        },
        // OTHER COUNTRIES
        {
          _id: 'Afghanistan',
          name: $gettext('Afghanistan'),
        },
        {
          _id: 'Ägypten',
          name: $gettext('Egypt'),
        },
        {
          _id: 'Albanien',
          name: $gettext('Albania'),
        },
        {
          _id: 'Algerien',
          name: $gettext('Algeria'),
        },
        {
          _id: 'Andorra',
          name: $gettext('Andorra'),
        },
        {
          _id: 'Angola',
          name: $gettext('Angola'),
        },
        {
          _id: 'Antigua und Barbuda',
          name: $gettext('Antigua and Barbuda'),
        },
        {
          _id: 'Äquatorialguinea',
          name: $gettext('Equatorial Guinea'),
        },
        {
          _id: 'Argentinien',
          name: $gettext('Argentina'),
        },
        {
          _id: 'Armenien',
          name: $gettext('Armenia'),
        },
        {
          _id: 'Aserbaidschan',
          name: $gettext('Azerbaijan'),
        },
        {
          _id: 'Äthiopien',
          name: $gettext('Ethiopia'),
        },
        {
          _id: 'Australien',
          name: $gettext('Australia'),
        },
        {
          _id: 'Bahamas',
          name: $gettext('Bahamas'),
        },
        {
          _id: 'Bahrain',
          name: $gettext('Bahrain'),
        },
        {
          _id: 'Bangladesch',
          name: $gettext('Bangladesh'),
        },
        {
          _id: 'Barbados',
          name: $gettext('Barbados'),
        },
        {
          _id: 'Belarus',
          name: $gettext('Belarus'),
        },
        {
          _id: 'Belgien',
          name: $gettext('Belgium'),
        },
        {
          _id: 'Belize',
          name: $gettext('Belize'),
        },
        {
          _id: 'Benin',
          name: $gettext('Benin'),
        },
        {
          _id: 'Bhutan',
          name: $gettext('Bhutan'),
        },
        {
          _id: 'Bolivien',
          name: $gettext('Bolivia'),
        },
        {
          _id: 'Bosnien Herzegowina',
          name: $gettext('Bosnia and Herzegovina'),
        },
        {
          _id: 'Botsuana',
          name: $gettext('Botswana'),
        },
        {
          _id: 'Brasilien',
          name: $gettext('Brazil'),
        },
        {
          _id: 'Brunei Darussalam',
          name: $gettext('Brunei Darussalam'),
        },
        {
          _id: 'Bulgarien',
          name: $gettext('Bulgaria'),
        },
        {
          _id: 'Burkina Faso',
          name: $gettext('Burkina Faso'),
        },
        {
          _id: 'Burundi',
          name: $gettext('Burundi'),
        },
        {
          _id: 'Cabo Verde',
          name: $gettext('Cabo Verde'),
        },
        {
          _id: 'Chile',
          name: $gettext('Chile'),
        },
        {
          _id: 'China (VR)',
          name: $gettext('China (PR)'),
        },
        {
          _id: 'Cookinseln',
          name: $gettext('Cook Islands'),
        },
        {
          _id: 'Costa Rica',
          name: $gettext('Costa Rica'),
        },
        {
          _id: "Côte d'Ivoire",
          name: $gettext("Côte d'Ivoire"),
        },
        {
          _id: 'Dänemark',
          name: $gettext('Denmark'),
        },
        {
          _id: 'Dominica',
          name: $gettext('Dominica'),
        },
        {
          _id: 'Dominikanische Republik',
          name: $gettext('Dominican Republic'),
        },
        {
          _id: 'Dschibuti',
          name: $gettext('Djibouti'),
        },
        {
          _id: 'Ecuador',
          name: $gettext('Ecuador'),
        },
        {
          _id: 'El Salvador',
          name: $gettext('El Salvador'),
        },
        {
          _id: 'Eritrea',
          name: $gettext('Eritrea'),
        },
        {
          _id: 'Estland',
          name: $gettext('Estonia'),
        },
        {
          _id: 'Eswatini',
          name: $gettext('Eswatini'),
        },
        {
          _id: 'Fidschi',
          name: $gettext('Fiji'),
        },
        {
          _id: 'Finnland',
          name: $gettext('Finland'),
        },
        {
          _id: 'Frankreich',
          name: $gettext('France'),
        },
        {
          _id: 'Gabun',
          name: $gettext('Gabon'),
        },
        {
          _id: 'Gambia',
          name: $gettext('Gambia'),
        },
        {
          _id: 'Georgien',
          name: $gettext('Georgia'),
        },
        {
          _id: 'Ghana',
          name: $gettext('Ghana'),
        },
        {
          _id: 'Grenada',
          name: $gettext('Grenada'),
        },
        {
          _id: 'Griechenland',
          name: $gettext('Greece'),
        },
        {
          _id: 'Großbritannien (UK)',
          name: $gettext('Great Britain (UK)'),
        },
        {
          _id: 'Guatemala',
          name: $gettext('Guatemala'),
        },
        {
          _id: 'Guinea',
          name: $gettext('Guinea'),
        },
        {
          _id: 'Guinea-Bissau',
          name: $gettext('Guinea-Bissau'),
        },
        {
          _id: 'Guyana',
          name: $gettext('Guyana'),
        },
        {
          _id: 'Haiti',
          name: $gettext('Haiti'),
        },
        {
          _id: 'Honduras',
          name: $gettext('Honduras'),
        },
        {
          _id: 'Indien',
          name: $gettext('India'),
        },
        {
          _id: 'Indonesien',
          name: $gettext('Indonesia'),
        },
        {
          _id: 'Irak',
          name: $gettext('Iraq'),
        },
        {
          _id: 'Iran',
          name: $gettext('Iran'),
        },
        {
          _id: 'Irland',
          name: $gettext('Ireland'),
        },
        {
          _id: 'Island',
          name: $gettext('Iceland'),
        },
        {
          _id: 'Israel',
          name: $gettext('Israel'),
        },
        {
          _id: 'Italien',
          name: $gettext('Italy'),
        },
        {
          _id: 'Jamaika',
          name: $gettext('Jamaica'),
        },
        {
          _id: 'Japan',
          name: $gettext('Japan'),
        },
        {
          _id: 'Jemen',
          name: $gettext('Yemen'),
        },
        {
          _id: 'Jordanien',
          name: $gettext('Jordan'),
        },
        {
          _id: 'Kambodscha',
          name: $gettext('Cambodia'),
        },
        {
          _id: 'Kamerun',
          name: $gettext('Cameroon'),
        },
        {
          _id: 'Kanada',
          name: $gettext('Canada'),
        },
        {
          _id: 'Kasachstan',
          name: $gettext('Kazakhstan'),
        },
        {
          _id: 'Katar',
          name: $gettext('Qatar'),
        },
        {
          _id: 'Kenia',
          name: $gettext('Kenya'),
        },
        {
          _id: 'Kirgisistan',
          name: $gettext('Kyrgyzstan'),
        },
        {
          _id: 'Kiribati',
          name: $gettext('Kiribati'),
        },
        {
          _id: 'Kolumbien',
          name: $gettext('Colombia'),
        },
        {
          _id: 'Komoren',
          name: $gettext('Comoros'),
        },
        {
          _id: 'Kongo',
          name: $gettext('Congo'),
        },
        {
          _id: 'Kongo (Demokratische Republik)',
          name: $gettext('Congo (Democratic Republic of)'),
        },
        {
          _id: 'Korea (Demokratische Volksrepublik)',
          name: $gettext("Korea (Democratic People's Republic of)"),
        },
        {
          _id: 'Korea (Republik)',
          name: $gettext('Korea (Republic of)'),
        },
        {
          _id: 'Kosovo',
          name: $gettext('Kosovo'),
        },
        {
          _id: 'Kroatien',
          name: $gettext('Croatia'),
        },
        {
          _id: 'Kuba',
          name: $gettext('Cuba'),
        },
        {
          _id: 'Kuwait',
          name: $gettext('Kuwait'),
        },
        {
          _id: 'Laos',
          name: $gettext('Laos'),
        },
        {
          _id: 'Lesotho',
          name: $gettext('Lesotho'),
        },
        {
          _id: 'Lettland',
          name: $gettext('Latvia'),
        },
        {
          _id: 'Libanon',
          name: $gettext('Lebanon'),
        },
        {
          _id: 'Liberia',
          name: $gettext('Liberia'),
        },
        {
          _id: 'Libyen',
          name: $gettext('Libya'),
        },
        {
          _id: 'Liechtenstein',
          name: $gettext('Liechtenstein'),
        },
        {
          _id: 'Litauen',
          name: $gettext('Lithuania'),
        },
        {
          _id: 'Luxemburg',
          name: $gettext('Luxembourg'),
        },
        {
          _id: 'Madagaskar',
          name: $gettext('Madagascar'),
        },
        {
          _id: 'Malawi',
          name: $gettext('Malawi'),
        },
        {
          _id: 'Malaysia',
          name: $gettext('Malaysia'),
        },
        {
          _id: 'Malediven',
          name: $gettext('Maldives'),
        },
        {
          _id: 'Mali',
          name: $gettext('Mali'),
        },
        {
          _id: 'Malta',
          name: $gettext('Malta'),
        },
        {
          _id: 'Marokko',
          name: $gettext('Morocco'),
        },
        {
          _id: 'Marshallinseln',
          name: $gettext('Marshall Islands'),
        },
        {
          _id: 'Mauretanien',
          name: $gettext('Mauritania'),
        },
        {
          _id: 'Mauritius',
          name: $gettext('Mauritius'),
        },
        {
          _id: 'Mexiko',
          name: $gettext('Mexico'),
        },
        {
          _id: 'Mikronesien',
          name: $gettext('Micronesia'),
        },
        {
          _id: 'Moldau',
          name: $gettext('Moldova'),
        },
        {
          _id: 'Monaco',
          name: $gettext('Monaco'),
        },
        {
          _id: 'Mongolei',
          name: $gettext('Mongolia'),
        },
        {
          _id: 'Montenegro',
          name: $gettext('Montenegro'),
        },
        {
          _id: 'Mosambik',
          name: $gettext('Mozambique'),
        },
        {
          _id: 'Myanmar',
          name: $gettext('Myanmar'),
        },
        {
          _id: 'Namibia',
          name: $gettext('Namibia'),
        },
        {
          _id: 'Nauru',
          name: $gettext('Nauru'),
        },
        {
          _id: 'Nepal',
          name: $gettext('Nepal'),
        },
        {
          _id: 'Neuseeland',
          name: $gettext('New Zealand'),
        },
        {
          _id: 'Nicaragua',
          name: $gettext('Nicaragua'),
        },
        {
          _id: 'Niederlande',
          name: $gettext('Netherlands'),
        },
        {
          _id: 'Niger',
          name: $gettext('Niger'),
        },
        {
          _id: 'Nigeria',
          name: $gettext('Nigeria'),
        },
        {
          _id: 'Nordmazedonien',
          name: $gettext('North Macedonia'),
        },
        {
          _id: 'Norwegen',
          name: $gettext('Norway'),
        },
        {
          _id: 'Oman',
          name: $gettext('Oman'),
        },
        {
          _id: 'Pakistan',
          name: $gettext('Pakistan'),
        },
        {
          _id: 'Palau',
          name: $gettext('Palau'),
        },
        {
          _id: 'Panama',
          name: $gettext('Panama'),
        },
        {
          _id: 'Papua-Neuguinea',
          name: $gettext('Papua New Guinea'),
        },
        {
          _id: 'Paraguay',
          name: $gettext('Paraguay'),
        },
        {
          _id: 'Peru',
          name: $gettext('Peru'),
        },
        {
          _id: 'Philippinen',
          name: $gettext('Philippines'),
        },
        {
          _id: 'Polen',
          name: $gettext('Poland'),
        },
        {
          _id: 'Portugal',
          name: $gettext('Portugal'),
        },
        {
          _id: 'Ruanda',
          name: $gettext('Rwanda'),
        },
        {
          _id: 'Rumänien',
          name: $gettext('Romania'),
        },
        {
          _id: 'Russland',
          name: $gettext('Russia'),
        },
        {
          _id: 'Salomonen',
          name: $gettext('Solomon Islands'),
        },
        {
          _id: 'Sambia',
          name: $gettext('Zambia'),
        },
        {
          _id: 'Samoa',
          name: $gettext('Samoa'),
        },
        {
          _id: 'San Marino',
          name: $gettext('San Marino'),
        },
        {
          _id: 'São Tomé und Príncipe',
          name: $gettext('São Tomé und Príncipe'),
        },
        {
          _id: 'Saudi-Arabien',
          name: $gettext('Saudi Arabia'),
        },
        {
          _id: 'Schweden',
          name: $gettext('Sweden'),
        },
        {
          _id: 'Senegal',
          name: $gettext('Senegal'),
        },
        {
          _id: 'Serbien',
          name: $gettext('Serbia'),
        },
        {
          _id: 'Seychellen',
          name: $gettext('Seychelles'),
        },
        {
          _id: 'Sierra Leone',
          name: $gettext('Sierra Leone'),
        },
        {
          _id: 'Simbabwe',
          name: $gettext('Zimbabwe'),
        },
        {
          _id: 'Singapur',
          name: $gettext('Singapore'),
        },
        {
          _id: 'Slowakei',
          name: $gettext('Slovakia'),
        },
        {
          _id: 'Slowenien',
          name: $gettext('Slovenia'),
        },
        {
          _id: 'Somalia',
          name: $gettext('Somalia'),
        },
        {
          _id: 'Spanien',
          name: $gettext('Spain'),
        },
        {
          _id: 'Sri Lanka',
          name: $gettext('Sri Lanka'),
        },
        {
          _id: 'St. Kitts und Nevis',
          name: $gettext('St. Kitts and Nevis'),
        },
        {
          _id: 'St. Lucia',
          name: $gettext('St. Lucia'),
        },
        {
          _id: 'St. Vincent und die Grenadinen',
          name: $gettext('St. Vincent and the Grenadines'),
        },
        {
          _id: 'Südafrika',
          name: $gettext('South Africa'),
        },
        {
          _id: 'Sudan',
          name: $gettext('Sudan'),
        },
        {
          _id: 'Südsudan',
          name: $gettext('South Sudan'),
        },
        {
          _id: 'Suriname',
          name: $gettext('Suriname'),
        },
        {
          _id: 'Syrien',
          name: $gettext('Syria'),
        },
        {
          _id: 'Tadschikistan',
          name: $gettext('Tajikistan'),
        },
        {
          _id: 'Taiwan',
          name: $gettext('Taiwan'),
        },
        {
          _id: 'Tansania',
          name: $gettext('Tanzania'),
        },
        {
          _id: 'Thailand',
          name: $gettext('Thailand'),
        },
        {
          _id: 'Timor-Leste',
          name: $gettext('Timor-Leste'),
        },
        {
          _id: 'Togo',
          name: $gettext('Togo'),
        },
        {
          _id: 'Tonga',
          name: $gettext('Tonga'),
        },
        {
          _id: 'Trinidad und Tobago',
          name: $gettext('Trinidad and Tobago'),
        },
        {
          _id: 'Tschad',
          name: $gettext('Chad'),
        },
        {
          _id: 'Tschechische Republik',
          name: $gettext('Czech Republic'),
        },
        {
          _id: 'Tunesien',
          name: $gettext('Tunisia'),
        },
        {
          _id: 'Türkei',
          name: $gettext('Türkiye'),
        },
        {
          _id: 'Turkmenistan',
          name: $gettext('Turkmenistan'),
        },
        {
          _id: 'Tuvalu',
          name: $gettext('Tuvalu'),
        },
        {
          _id: 'Uganda',
          name: $gettext('Uganda'),
        },
        {
          _id: 'Ukraine',
          name: $gettext('Ukraine'),
        },
        {
          _id: 'Ungarn',
          name: $gettext('Hungary'),
        },
        {
          _id: 'Uruguay',
          name: $gettext('Uruguay'),
        },
        {
          _id: 'USA',
          name: $gettext('USA'),
        },
        {
          _id: 'Usbekistan',
          name: $gettext('Uzbekistan'),
        },
        {
          _id: 'Vanuatu',
          name: $gettext('Vanuatu'),
        },
        {
          _id: 'Vatikanstadt',
          name: $gettext('Vatican City'),
        },
        {
          _id: 'Venezuela',
          name: $gettext('Venezuela'),
        },
        {
          _id: 'Vereinigte Arabische Emirate',
          name: $gettext('United Arab Emirates'),
        },
        {
          _id: 'Vietnam',
          name: $gettext('Vietnam'),
        },
        {
          _id: 'Zentralafrikanische Republik',
          name: $gettext('Central African Republic'),
        },
        {
          _id: 'Zypern',
          name: $gettext('Cyprus'),
        },
      ]
    }
  },
  ///-------------------------------------------------------------------
  /// GETTERS
  ///-------------------------------------------------------------------
  getters: {
    /**
     * Searches for the country with the given ID and returns its name.
     * @param id The ID of the country.
     * @returns The name of the country.
     */
    getNameOf: (state: State) => {
      return (id: string) => {
        return state.countries.find(country => country._id === id)?.name || '';
      }
    },
  },
})