import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createSlots as _createSlots, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bp-mail-list-generator-view" }
const _hoisted_2 = { class: "flex-column gap-md" }
const _hoisted_3 = {
  class: "flex gap-md",
  style: {"align-items":"center"}
}
const _hoisted_4 = {
  class: "flex gap-md",
  style: {"align-items":"center"}
}
const _hoisted_5 = {
  key: 0,
  class: "flex-column gap-md"
}
const _hoisted_6 = { class: "flex gap-md" }
const _hoisted_7 = { class: "flex gap-md" }
const _hoisted_8 = {
  key: 0,
  class: "flex-column gap-md"
}
const _hoisted_9 = { class: "flex-column gap-md" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_translate = _resolveComponent("translate")!
  const _component_bp_view_header = _resolveComponent("bp-view-header")!
  const _component_bp_input = _resolveComponent("bp-input")!
  const _component_bp_icon = _resolveComponent("bp-icon")!
  const _component_bp_select = _resolveComponent("bp-select")!
  const _component_bp_fieldset = _resolveComponent("bp-fieldset")!
  const _component_bp_card = _resolveComponent("bp-card")!
  const _component_bp_button = _resolveComponent("bp-button")!
  const _component_bp_table = _resolveComponent("bp-table")!
  const _component_bp_window = _resolveComponent("bp-window")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createVNode(_component_bp_view_header, {
      image: "/static/background/app_background_maillist_generator.jpg",
      icon: ['fad','clipboard-list']
    }, {
      default: _withCtx(() => [
        _createVNode(_component_translate, null, {
          default: _withCtx(() => _cache[24] || (_cache[24] = [
            _createTextVNode("Mail list generator")
          ])),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_bp_card, {
      "fill-height": "",
      "fill-width": ""
    }, {
      footer: _withCtx(() => [
        _createVNode(_component_bp_button, {
          icon: "clipboard-list",
          color: "green",
          onClick: _ctx.generateMailList
        }, {
          default: _withCtx(() => [
            _createVNode(_component_translate, null, {
              default: _withCtx(() => _cache[43] || (_cache[43] = [
                _createTextVNode("Generate mail list")
              ])),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["onClick"])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_bp_fieldset, { "intense-border": "" }, {
            legend: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_bp_input, {
                  "model-value": Object.keys(_ctx.filter.by)[0],
                  text: _ctx.$gettext('Filter by language'),
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.filter.by = Object.fromEntries([[$event, []]]))),
                  seamless: "",
                  name: "filterBy",
                  type: "radio",
                  value: "language"
                }, null, 8, ["model-value", "text"]),
                _createVNode(_component_bp_icon, {
                  icon: "slash-forward",
                  "fixed-width": false
                }),
                _createVNode(_component_bp_input, {
                  "model-value": Object.keys(_ctx.filter.by)[0],
                  text: _ctx.$gettext('Filter by country'),
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.filter.by = Object.fromEntries([[$event, []]]))),
                  seamless: "",
                  name: "filterBy",
                  type: "radio",
                  value: "country"
                }, null, 8, ["model-value", "text"])
              ])
            ]),
            default: _withCtx(() => [
              (Object.keys(_ctx.filter.by)[0] === 'language')
                ? (_openBlock(), _createBlock(_component_bp_select, {
                    key: 0,
                    data: _ctx.languageStore.languages,
                    indent: 1.125,
                    label: _ctx.$gettext('Language'),
                    clearable: "",
                    multiple: "",
                    "search-property": "name",
                    modelValue: _ctx.filter.by.language,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.filter.by.language) = $event))
                  }, null, 8, ["data", "label", "modelValue"]))
                : (Object.keys(_ctx.filter.by)[0] === 'country')
                  ? (_openBlock(), _createBlock(_component_bp_select, {
                      key: 1,
                      data: _ctx.countryStore.countries,
                      indent: 1.125,
                      label: _ctx.$gettext('Country'),
                      clearable: "",
                      multiple: "",
                      "search-property": "name",
                      modelValue: _ctx.filter.by.country,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.filter.by.country) = $event))
                    }, null, 8, ["data", "label", "modelValue"]))
                  : _createCommentVNode("", true)
            ]),
            _: 1
          }),
          _createVNode(_component_bp_select, {
            data: _ctx.supportStatuses,
            label: _ctx.$gettext('Support'),
            clearable: "",
            multiple: "",
            "search-property": "name",
            modelValue: _ctx.filter.support,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.filter.support) = $event))
          }, null, 8, ["data", "label", "modelValue"]),
          _cache[41] || (_cache[41] = _createElementVNode("hr", null, null, -1)),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_translate, { tag: "h2" }, {
              default: _withCtx(() => _cache[25] || (_cache[25] = [
                _createTextVNode("Products")
              ])),
              _: 1
            }),
            _createVNode(_component_bp_input, {
              type: "radio",
              name: "products",
              value: "one",
              "model-value": _ctx.productsCombination,
              "onUpdate:modelValue": _ctx.updateProductsCombination,
              text: _ctx.$gettext('One'),
              seamless: ""
            }, null, 8, ["model-value", "onUpdate:modelValue", "text"]),
            _createVNode(_component_bp_icon, {
              icon: "slash-forward",
              "fixed-width": false
            }),
            _createVNode(_component_bp_input, {
              type: "radio",
              name: "products",
              value: "all",
              "model-value": _ctx.productsCombination,
              "onUpdate:modelValue": _ctx.updateProductsCombination,
              text: _ctx.$gettext('All'),
              seamless: ""
            }, null, 8, ["model-value", "onUpdate:modelValue", "text"])
          ]),
          _createVNode(_component_bp_card, {
            "fill-width": "",
            "active-tab": _ctx.activeTab,
            tabs: _ctx.productTabs,
            "tabs-position": "inside"
          }, {
            "tab-bestinformed": _withCtx(() => [
              _createVNode(_component_bp_input, {
                "model-value": Object.keys(_ctx.filter.products[_ctx.productsCombination]!).includes('bestinformed'),
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (_ctx.toggleProduct('bestinformed'))),
                class: "bp-customer-editor__tab-checkbox",
                type: "checkbox",
                seamless: ""
              }, null, 8, ["model-value"]),
              _cache[26] || (_cache[26] = _createElementVNode("span", null, [
                _createTextVNode("Cordaware bestinformed"),
                _createElementVNode("sup", null, "®")
              ], -1))
            ]),
            "tab-bestproxy": _withCtx(() => [
              _createVNode(_component_bp_input, {
                "model-value": Object.keys(_ctx.filter.products[_ctx.productsCombination]!).includes('bestproxy'),
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (_ctx.toggleProduct('bestproxy'))),
                class: "bp-customer-editor__tab-checkbox",
                type: "checkbox",
                seamless: ""
              }, null, 8, ["model-value"]),
              _cache[27] || (_cache[27] = _createElementVNode("span", null, "bestproxy", -1))
            ]),
            "tab-hosting": _withCtx(() => [
              _createVNode(_component_bp_input, {
                "model-value": Object.keys(_ctx.filter.products[_ctx.productsCombination]!).includes('hosting'),
                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (_ctx.toggleProduct('hosting'))),
                class: "bp-customer-editor__tab-checkbox",
                type: "checkbox",
                seamless: ""
              }, null, 8, ["model-value"]),
              _cache[28] || (_cache[28] = _createElementVNode("span", null, "Hosting", -1))
            ]),
            "tab-bestzero": _withCtx(() => [
              _createVNode(_component_bp_input, {
                "model-value": Object.keys(_ctx.filter.products[_ctx.productsCombination]!).includes('bestzero'),
                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => (_ctx.toggleProduct('bestzero'))),
                class: "bp-customer-editor__tab-checkbox",
                type: "checkbox",
                seamless: ""
              }, null, 8, ["model-value"]),
              _cache[29] || (_cache[29] = _createElementVNode("span", null, [
                _createTextVNode("Cordaware bestzero"),
                _createElementVNode("sup", null, "®")
              ], -1))
            ]),
            "tab-content-bestinformed": _withCtx(() => [
              (_ctx.filter.products[_ctx.productsCombination]!.bestinformed)
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    _createVNode(_component_bp_fieldset, { "intense-border": "" }, _createSlots({
                      legend: _withCtx(() => [
                        _createVNode(_component_bp_input, {
                          "model-value": Object.keys(_ctx.filter.products[_ctx.productsCombination]!.bestinformed || []).includes('bestinformed5'),
                          text: _ctx.$gettext('Version 5'),
                          "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => (_ctx.toggleBestinformedVersion('bestinformed5'))),
                          seamless: "",
                          type: "checkbox"
                        }, null, 8, ["model-value", "text"])
                      ]),
                      _: 2
                    }, [
                      (_ctx.filter.products[_ctx.productsCombination]!.bestinformed!.bestinformed5)
                        ? {
                            name: "default",
                            fn: _withCtx(() => [
                              (_ctx.editionStore.loaded)
                                ? (_openBlock(), _createBlock(_component_bp_select, {
                                    key: 0,
                                    data: _ctx.editionStore.editions.bestinformed5.filter(edition => !edition._id.startsWith('Infoband')),
                                    indent: 2.375,
                                    label: _ctx.$gettext('Edition'),
                                    clearable: "",
                                    multiple: "",
                                    "search-property": "name",
                                    modelValue: _ctx.filter.products[_ctx.productsCombination]!.bestinformed!.bestinformed5!.edition,
                                    "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.filter.products[_ctx.productsCombination]!.bestinformed!.bestinformed5!.edition) = $event))
                                  }, null, 8, ["data", "label", "modelValue"]))
                                : _createCommentVNode("", true)
                            ]),
                            key: "0"
                          }
                        : {
                            name: "default",
                            fn: _withCtx(() => [
                              (Object.keys(_ctx.filter.products[_ctx.productsCombination]!.bestinformed || {}).length > 0)
                                ? (_openBlock(), _createBlock(_component_translate, {
                                    key: 0,
                                    tag: "em",
                                    class: "pt-md pb-md",
                                    "translate-params": { version: 5 }
                                  }, {
                                    default: _withCtx(() => _cache[30] || (_cache[30] = [
                                      _createTextVNode("Cordaware bestinformed"),
                                      _createElementVNode("sup", null, "®", -1),
                                      _createTextVNode(" %{version} customers will "),
                                      _createElementVNode("strong", null, "not", -1),
                                      _createTextVNode(" be included in the mail list.")
                                    ])),
                                    _: 1
                                  }))
                                : (_openBlock(), _createBlock(_component_translate, {
                                    key: 1,
                                    tag: "em",
                                    class: "pt-md pb-md"
                                  }, {
                                    default: _withCtx(() => _cache[31] || (_cache[31] = [
                                      _createTextVNode("No filters selected, therefore "),
                                      _createElementVNode("strong", null, "all", -1),
                                      _createTextVNode(" Cordaware bestinformed"),
                                      _createElementVNode("sup", null, "®", -1),
                                      _createTextVNode(" customers will be included in the mail list.")
                                    ])),
                                    _: 1
                                  }))
                            ]),
                            key: "1"
                          }
                    ]), 1024),
                    _createVNode(_component_bp_fieldset, { "intense-border": "" }, _createSlots({
                      legend: _withCtx(() => [
                        _createVNode(_component_bp_input, {
                          "model-value": Object.keys(_ctx.filter.products[_ctx.productsCombination]!.bestinformed || []).includes('bestinformed6'),
                          text: _ctx.$gettext('Version 6'),
                          "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => (_ctx.toggleBestinformedVersion('bestinformed6'))),
                          seamless: "",
                          type: "checkbox"
                        }, null, 8, ["model-value", "text"])
                      ]),
                      _: 2
                    }, [
                      (_ctx.filter.products[_ctx.productsCombination]!.bestinformed!.bestinformed6)
                        ? {
                            name: "default",
                            fn: _withCtx(() => [
                              (_ctx.editionStore.loaded)
                                ? (_openBlock(), _createBlock(_component_bp_select, {
                                    key: 0,
                                    data: _ctx.editionStore.editions.bestinformed6,
                                    indent: 2.375,
                                    label: _ctx.$gettext('Edition'),
                                    clearable: "",
                                    multiple: "",
                                    "search-property": "name",
                                    modelValue: _ctx.filter.products[_ctx.productsCombination]!.bestinformed!.bestinformed6!.edition,
                                    "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.filter.products[_ctx.productsCombination]!.bestinformed!.bestinformed6!.edition) = $event))
                                  }, null, 8, ["data", "label", "modelValue"]))
                                : _createCommentVNode("", true),
                              _createVNode(_component_bp_fieldset, {
                                "intense-border": "",
                                legend: _ctx.$gettext('Apps')
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_bp_select, {
                                    data: _ctx.apps('included'),
                                    indent: 3.5,
                                    label: _ctx.$gettext('Included'),
                                    clearable: "",
                                    multiple: "",
                                    "hide-selected": "",
                                    "search-property": "name",
                                    modelValue: _ctx.filter.products[_ctx.productsCombination]!.bestinformed!.bestinformed6!.apps!.included![_ctx.includedAppsCombination],
                                    "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.filter.products[_ctx.productsCombination]!.bestinformed!.bestinformed6!.apps!.included![_ctx.includedAppsCombination]) = $event))
                                  }, {
                                    text: _withCtx(() => [
                                      _createElementVNode("div", _hoisted_6, [
                                        _createVNode(_component_bp_input, {
                                          type: "radio",
                                          name: "indcluded-apps",
                                          value: "one",
                                          "model-value": _ctx.includedAppsCombination,
                                          "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => (_ctx.updateAppsCombination($event, 'included'))),
                                          text: _ctx.$gettext('One'),
                                          seamless: ""
                                        }, null, 8, ["model-value", "text"]),
                                        _createVNode(_component_bp_icon, {
                                          icon: "slash-forward",
                                          "fixed-width": false
                                        }),
                                        _createVNode(_component_bp_input, {
                                          type: "radio",
                                          name: "included-apps",
                                          value: "all",
                                          "model-value": _ctx.includedAppsCombination,
                                          "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => (_ctx.updateAppsCombination($event, 'included'))),
                                          text: _ctx.$gettext('All'),
                                          seamless: ""
                                        }, null, 8, ["model-value", "text"])
                                      ])
                                    ]),
                                    _: 1
                                  }, 8, ["data", "label", "modelValue"]),
                                  _createVNode(_component_bp_select, {
                                    data: _ctx.apps('excluded'),
                                    indent: 3.5,
                                    label: _ctx.$gettext('Excluded'),
                                    clearable: "",
                                    multiple: "",
                                    "hide-selected": "",
                                    "search-property": "name",
                                    modelValue: _ctx.filter.products[_ctx.productsCombination]!.bestinformed!.bestinformed6!.apps!.excluded![_ctx.excludedAppsCombination],
                                    "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.filter.products[_ctx.productsCombination]!.bestinformed!.bestinformed6!.apps!.excluded![_ctx.excludedAppsCombination]) = $event))
                                  }, {
                                    text: _withCtx(() => [
                                      _createElementVNode("div", _hoisted_7, [
                                        _createVNode(_component_bp_input, {
                                          type: "radio",
                                          name: "excluded-apps",
                                          value: "one",
                                          "model-value": _ctx.excludedAppsCombination,
                                          "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => (_ctx.updateAppsCombination($event, 'excluded'))),
                                          text: _ctx.$gettext('One'),
                                          seamless: ""
                                        }, null, 8, ["model-value", "text"]),
                                        _createVNode(_component_bp_icon, {
                                          icon: "slash-forward",
                                          "fixed-width": false
                                        }),
                                        _createVNode(_component_bp_input, {
                                          type: "radio",
                                          name: "excluded-apps",
                                          value: "all",
                                          "model-value": _ctx.excludedAppsCombination,
                                          "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => (_ctx.updateAppsCombination($event, 'excluded'))),
                                          text: _ctx.$gettext('All'),
                                          seamless: ""
                                        }, null, 8, ["model-value", "text"])
                                      ])
                                    ]),
                                    _: 1
                                  }, 8, ["data", "label", "modelValue"])
                                ]),
                                _: 1
                              }, 8, ["legend"]),
                              _createVNode(_component_bp_select, {
                                data: _ctx.licenseTypes,
                                modelValue: _ctx.filter.products[_ctx.productsCombination]!.bestinformed!.bestinformed6!.licenseType,
                                "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.filter.products[_ctx.productsCombination]!.bestinformed!.bestinformed6!.licenseType) = $event)),
                                indent: 2.375,
                                label: _ctx.$gettext('Purchase') + ' / ' + _ctx.$gettext('Subscription'),
                                "search-property": "name"
                              }, null, 8, ["data", "modelValue", "label"])
                            ]),
                            key: "0"
                          }
                        : {
                            name: "default",
                            fn: _withCtx(() => [
                              (Object.keys(_ctx.filter.products[_ctx.productsCombination]!.bestinformed || {}).length > 0)
                                ? (_openBlock(), _createBlock(_component_translate, {
                                    key: 0,
                                    tag: "em",
                                    class: "pt-md pb-md",
                                    "translate-params": { version: 6 }
                                  }, {
                                    default: _withCtx(() => _cache[32] || (_cache[32] = [
                                      _createTextVNode("Cordaware bestinformed"),
                                      _createElementVNode("sup", null, "®", -1),
                                      _createTextVNode(" %{version} customers will "),
                                      _createElementVNode("strong", null, "not", -1),
                                      _createTextVNode(" be included in the mail list.")
                                    ])),
                                    _: 1
                                  }))
                                : (_openBlock(), _createBlock(_component_translate, {
                                    key: 1,
                                    tag: "em",
                                    class: "pt-md pb-md"
                                  }, {
                                    default: _withCtx(() => _cache[33] || (_cache[33] = [
                                      _createTextVNode("No filters selected, therefore "),
                                      _createElementVNode("strong", null, "all", -1),
                                      _createTextVNode(" Cordaware bestinformed"),
                                      _createElementVNode("sup", null, "®", -1),
                                      _createTextVNode(" customers will be included in the mail list.")
                                    ])),
                                    _: 1
                                  }))
                            ]),
                            key: "1"
                          }
                    ]), 1024)
                  ]))
                : (_openBlock(), _createBlock(_component_translate, {
                    key: 1,
                    tag: "em",
                    class: "pt-md pb-md"
                  }, {
                    default: _withCtx(() => _cache[34] || (_cache[34] = [
                      _createTextVNode("Select the product to further filter the mail list.")
                    ])),
                    _: 1
                  }))
            ]),
            "tab-content-bestproxy": _withCtx(() => [
              (_ctx.filter.products[_ctx.productsCombination]!.bestproxy)
                ? (_openBlock(), _createBlock(_component_translate, {
                    key: 0,
                    tag: "em",
                    class: "pt-md pb-md"
                  }, {
                    default: _withCtx(() => _cache[35] || (_cache[35] = [
                      _createTextVNode("No further filter available.")
                    ])),
                    _: 1
                  }))
                : (_openBlock(), _createBlock(_component_translate, {
                    key: 1,
                    tag: "em",
                    class: "pt-md pb-md"
                  }, {
                    default: _withCtx(() => _cache[36] || (_cache[36] = [
                      _createTextVNode("Select the product to further filter the mail list.")
                    ])),
                    _: 1
                  }))
            ]),
            "tab-content-hosting": _withCtx(() => [
              (_ctx.filter.products[_ctx.productsCombination]!.hosting)
                ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                    _createVNode(_component_bp_select, {
                      data: _ctx.hostingStatuses,
                      label: _ctx.$gettext('Hosting status'),
                      clearable: "",
                      multiple: "",
                      "search-property": "name",
                      modelValue: _ctx.filter.products[_ctx.productsCombination]!.hosting!.status,
                      "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.filter.products[_ctx.productsCombination]!.hosting!.status) = $event))
                    }, null, 8, ["data", "label", "modelValue"])
                  ]))
                : (_openBlock(), _createBlock(_component_translate, {
                    key: 1,
                    tag: "em",
                    class: "pt-md pb-md"
                  }, {
                    default: _withCtx(() => _cache[37] || (_cache[37] = [
                      _createTextVNode("Select the product to further filter the mail list.")
                    ])),
                    _: 1
                  }))
            ]),
            "tab-content-bestzero": _withCtx(() => [
              (_ctx.filter.products[_ctx.productsCombination]!.bestzero)
                ? (_openBlock(), _createBlock(_component_translate, {
                    key: 0,
                    tag: "em",
                    class: "pt-md pb-md"
                  }, {
                    default: _withCtx(() => _cache[38] || (_cache[38] = [
                      _createTextVNode("No further filter available.")
                    ])),
                    _: 1
                  }))
                : (_openBlock(), _createBlock(_component_translate, {
                    key: 1,
                    tag: "em",
                    class: "pt-md pb-md"
                  }, {
                    default: _withCtx(() => _cache[39] || (_cache[39] = [
                      _createTextVNode("Select the product to further filter the mail list.")
                    ])),
                    _: 1
                  }))
            ]),
            _: 1
          }, 8, ["active-tab", "tabs"]),
          _cache[42] || (_cache[42] = _createElementVNode("hr", null, null, -1)),
          _createVNode(_component_translate, { tag: "h2" }, {
            default: _withCtx(() => _cache[40] || (_cache[40] = [
              _createTextVNode("Data records")
            ])),
            _: 1
          }),
          _createVNode(_component_bp_select, {
            data: [
            { _id: 'contact', name: _ctx.$gettext('Include contacts') },
            { _id: 'new_contact', name: _ctx.$gettext('Include registered users') },
          ],
            label: _ctx.$gettext('Contacts / registered users'),
            clearable: "",
            multiple: "",
            "search-property": "name",
            modelValue: _ctx.filter.docTypes,
            "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.filter.docTypes) = $event))
          }, null, 8, ["data", "label", "modelValue"])
        ])
      ]),
      _: 1
    }),
    _createVNode(_component_bp_window, {
      type: "success",
      "seamless-content": "",
      modelValue: _ctx.preview,
      "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.preview) = $event))
    }, {
      header: _withCtx(() => [
        _createElementVNode("div", _hoisted_9, [
          _createVNode(_component_translate, { tag: "h2" }, {
            default: _withCtx(() => _cache[44] || (_cache[44] = [
              _createTextVNode("Mail list")
            ])),
            _: 1
          }),
          _createElementVNode("span", null, [
            _createVNode(_component_translate, {
              "translate-plural": "Found <strong>%{mails} email addresses</strong>",
              "translate-n": _ctx.mailList.size,
              "translate-params": { mails: _ctx.mailList.size }
            }, {
              default: _withCtx(() => _cache[45] || (_cache[45] = [
                _createTextVNode("Found "),
                _createElementVNode("strong", null, "%{mails} email address", -1)
              ])),
              _: 1
            }, 8, ["translate-n", "translate-params"]),
            _cache[47] || (_cache[47] = _createTextVNode(" ")),
            _createVNode(_component_translate, {
              "translate-plural": "from <strong>%{companies} companies</strong>",
              "translate-n": new Set(_ctx.personList.map(person => person.company)).size,
              "translate-params": { companies: new Set(_ctx.personList.map(person => person.company)).size }
            }, {
              default: _withCtx(() => _cache[46] || (_cache[46] = [
                _createTextVNode("from "),
                _createElementVNode("strong", null, "%{companies} company", -1)
              ])),
              _: 1
            }, 8, ["translate-n", "translate-params"]),
            _cache[48] || (_cache[48] = _createTextVNode(": "))
          ])
        ])
      ]),
      footer: _withCtx(() => [
        _createVNode(_component_bp_button, {
          icon: "copy",
          color: "green",
          onClick: _ctx.copyToClipboardAndClosePreview
        }, {
          default: _withCtx(() => [
            _createVNode(_component_translate, null, {
              default: _withCtx(() => _cache[49] || (_cache[49] = [
                _createTextVNode("Copy to clipboard")
              ])),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["onClick"]),
        _createVNode(_component_bp_button, {
          icon: "xmark",
          color: "red",
          onClick: _cache[22] || (_cache[22] = ($event: any) => (_ctx.preview = false))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_translate, null, {
              default: _withCtx(() => _cache[50] || (_cache[50] = [
                _createTextVNode("Close")
              ])),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      default: _withCtx(() => [
        _createVNode(_component_bp_table, {
          data: _ctx.personList,
          headers: _ctx.headers,
          selectable: false,
          unwrap: ""
        }, null, 8, ["data", "headers"])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}